import React from 'react';
import clsx from 'clsx';

import classes from './index.module.scss';

interface Props {
  isOpen: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const HamburgerToggle: React.FunctionComponent<Props> = ({
  isOpen,
  onClick,
}) => {
  return (
    <div
      className={classes.hamburger}
      data-e2e-selector="hamburger-toggle"
      data-testid="hamburger-toggle"
      onClick={onClick}
    >
      <div
        className={clsx(
          classes.hamburgerBar,
          isOpen && classes.hamburgerBarOpen,
        )}
      />
      <div
        className={clsx(
          classes.hamburgerBar,
          isOpen && classes.hamburgerBarOpen,
        )}
      />
      <div
        className={clsx(
          classes.hamburgerBar,
          isOpen && classes.hamburgerBarOpen,
        )}
      />
    </div>
  );
};
