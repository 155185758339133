import React from 'react';

import { NavigationItemProps } from '../../../../types';

import { DirectLink } from './components/DirectLink';
import { NavigationGroup } from './components/NavigationGroup';

export const NavigationItem: React.FunctionComponent<NavigationItemProps> = ({
  label,
  subitems,
}) => {
  return !subitems && label.href !== undefined ? (
    <DirectLink
      hash={label.hash}
      href={label.href}
      name={label.name}
      isExternalUrl={label.isExternalUrl}
    />
  ) : (
    <NavigationGroup label={label.name} subitems={subitems ?? []} />
  );
};
