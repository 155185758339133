import {
  NavbarExternalSubitem,
  NavbarLocalSubitem,
  NavbarSubitem,
} from '@/types/Config/Navbar';

export const isNavigationBarSubitem = (
  subitem: NavbarSubitem,
): subitem is NavbarLocalSubitem | NavbarExternalSubitem => {
  return 'externalUrls' in subitem || 'label' in subitem;
};

export const isLocalSubitem = (
  subitem: NavbarSubitem,
): subitem is NavbarLocalSubitem => {
  return !('externalUrls' in subitem);
};
