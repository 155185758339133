import dynamic from 'next/dynamic';
import React from 'react';

import { HamburgerToggle } from './components/HamburgerToggle';
import classes from './index.module.scss';

const HamburgerNavigationContent = dynamic(
  () => import('./components/HamburgerNavigationContent'),
);

interface Props {
  onToggle: (isOpen: boolean) => void;
  isOpen: boolean;
}

export const HamburgerNavigation: React.FunctionComponent<Props> = ({
  onToggle,
  isOpen,
}) => {
  return (
    <div className={classes.hamburgerNavigationLayout}>
      <HamburgerToggle onClick={() => onToggle(!isOpen)} isOpen={isOpen} />
      <HamburgerNavigationContent isOpen={isOpen} />
    </div>
  );
};
