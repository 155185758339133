import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useContext } from 'react';
import clsx from 'clsx';

import ConfigsContext from '../../../../contexts/configs';

import classes from './index.module.scss';

import Button from '@/components/common/Button';

interface Props {
  className?: string;
}

export const AccountButtons: React.FunctionComponent<Props> = ({
  className,
}) => {
  const { t } = useTranslation('navbar');
  const { externalUrls, features } = useContext(ConfigsContext);

  return (
    <div className={clsx(classes.accountButtonsContainer, className)}>
      {features.isRegistrationEnabled && (
        <Button
          href={'/registration'}
          buttonType={'callToAction'}
          label={t('Sign up')}
        />
      )}
      <Button
        buttonType={'primaryLegacy'}
        href={externalUrls.panel}
        label={t('Log in')}
      />
    </div>
  );
};
