import useTranslation from 'next-translate/useTranslation';
import React, {
  ComponentProps,
  ComponentRef,
  forwardRef,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { useHoverDirty } from 'react-use';

import { NavigationGroupProps } from '../../../../../../types';
import { DEFAULT_CONFIG_KEY } from '../../../../../../../../constants';

import classes from './index.module.scss';

import {
  isLocalSubitem,
  isNavigationBarSubitem,
} from '@/components/Navbar/helpers';
import { I18nLink } from '@/components/I18nLink';

export const NavigationAnchorLabel = forwardRef(
  (props: ComponentProps<'a'> & { as: 'a' | 'span' }, ref) => {
    if (props.as === 'span') {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <span className={classes.navigationItemAnchor} ref={ref}>
          {props.children}
        </span>
      );
    } else if (props.as === 'a') {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        <a {...props} className={classes.navigationItemAnchor} ref={ref}>
          {props.children}
        </a>
      );
    } else {
      return null;
    }
  },
);

NavigationAnchorLabel.displayName = 'NavigationAnchorLabel';

export const NavigationGroup = ({ label, subitems }: NavigationGroupProps) => {
  const isTwoColumns = subitems.length > 4;
  const { t, lang } = useTranslation('navbar');

  const labelRef = useRef<ComponentRef<'span'>>(null);

  const [wasHovered, setWasHovered] = useState(false);
  const isLabelHovered = useHoverDirty(labelRef);

  if (!wasHovered && isLabelHovered) {
    setWasHovered(true);
  }

  return (
    <li
      className={clsx(
        classes.navigationItemGroup,
        isTwoColumns && classes.navigationItemGroupTwoColumns,
      )}
      data-e2e-selector="navigation-bar-item"
    >
      <span ref={labelRef} className={classes.navigationItemAnchor}>
        {t(label)}
      </span>
      {wasHovered && (
        <div
          className={clsx(
            classes.groupContainer,
            isTwoColumns && classes.groupContainerTwoColumns,
          )}
        >
          <ul
            className={clsx(classes.navigationGroup, {
              [classes.navigationGroupTwoColumns]: isTwoColumns,
            })}
          >
            {subitems.map((subitem) => {
              if (!isNavigationBarSubitem(subitem)) return null;
              return (
                <li className={classes.navigationGroupItem} key={subitem.label}>
                  {isLocalSubitem(subitem) ? (
                    <I18nLink href={`/${subitem.routeName}`}>
                      <a className={classes.navigationGroupAnchor}>
                        <span className={classes.navigationGroupAnchorLabel}>
                          {t(subitem.label)}
                        </span>
                        <p className={classes.navigationGroupAnchorDescription}>
                          {t(subitem.description)}
                        </p>
                      </a>
                    </I18nLink>
                  ) : (
                    <a
                      className={classes.navigationGroupAnchor}
                      target={subitem.openInNewTab ? '_blank' : undefined}
                      rel={subitem.openInNewTab ? 'noopener' : undefined}
                      href={
                        subitem.externalUrls[lang] ||
                        subitem.externalUrls[DEFAULT_CONFIG_KEY]
                      }
                    >
                      <span className={classes.navigationGroupAnchorLabel}>
                        {t(subitem.label)}
                      </span>
                      <p className={classes.navigationGroupAnchorDescription}>
                        {t(subitem.description)}
                      </p>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};
