import React from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';

import { checkIfHrefIsExternal } from '../../../helpers/checkIfHrefIsExternal';
import { I18nLink } from '../../I18nLink';

import classes from './index.module.scss';

import arrowSvgIcon from '@/images/common/arrow-right.svg';
import { FaIconLight } from '@/components/FaIconLight';

export const BUTTON_HOVER_DARKEN_LEVEL = 0.1;
export const ACTIVE_DARKEN_LEVEL = 0.2;
export const HOVER_TRANSITION_TIME = '0.2s';

export type ButtonSize = 'normal' | 'small' | 'large';
export type ButtonType =
  | 'callToAction'
  | 'primary'
  | 'secondary'
  | 'download'
  | 'primaryLegacy';

type IconName = 'arrow';

export const buttonTransitionSttings = {
  hoverDarkenLevel: '10%',
  activeDarkenLevel: '10%',
  borderDarkenLevel: '5%',
  hoverTransitionTime: '0.2s',
};

const ICON_HEIGHT_BY_BUTTON_SIZE_MAP: Record<ButtonSize, number> = {
  small: 13,
  normal: 13,
  large: 20,
};

const nameIconMap: Record<IconName, string> = {
  arrow: arrowSvgIcon.src,
};

type IconConfig =
  | {
      fontAwesomeIconSrc: IconDefinition;
      align?: 'center';
      placeAfterLabel?: boolean;
      iconName?: never;
    }
  | {
      fontAwesomeIconSrc?: never;
      align?: 'center';
      placeAfterLabel?: boolean;
      iconName: IconName;
    };

export interface Props {
  buttonSize?: ButtonSize;
  buttonType: ButtonType;
  className?: string;
  disabled?: boolean;
  e2e?: string;
  hash?: string;
  href?: string;
  hrefTarget?: '_blank';
  htmlType?: 'submit';
  icon?: IconConfig;
  label: string | React.ReactNode;
  onClick?: () => void;
  gtm?: string;
}

const Button: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  buttonSize = 'normal',
  buttonType,
  className,
  disabled = false,
  e2e,
  hash,
  href,
  hrefTarget,
  htmlType,
  icon,
  label,
  onClick,
  gtm,
}) => {
  const isRealButton = href === undefined || !!htmlType;
  const styledButton = isRealButton ? (
    <button
      className={clsx(
        classes.button,
        classes[buttonType],
        {
          [classes.small]: buttonSize === 'small',
          [classes.large]: buttonSize === 'large',
        },
        className,
      )}
      data-e2e-selector={e2e}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
      data-gtm={gtm}
    >
      <div
        className={clsx(classes.buttonContent, {
          [classes.buttonContentReverse]: icon?.placeAfterLabel,
          [classes.buttonContentDownload]: buttonType === 'download',
        })}
      >
        {icon && icon.fontAwesomeIconSrc && (
          <FaIconLight icon={icon.fontAwesomeIconSrc} />
        )}
        {icon && icon.iconName && (
          <img
            className={classes.svgIcon}
            src={nameIconMap[icon.iconName]}
            height={ICON_HEIGHT_BY_BUTTON_SIZE_MAP[buttonSize]}
            alt=""
          />
        )}
        <span>{label}</span>
      </div>
    </button>
  ) : (
    <a
      className={clsx(
        classes.button,
        classes[buttonType],
        {
          [classes.small]: buttonSize === 'small',
          [classes.large]: buttonSize === 'large',
        },
        className,
      )}
      data-e2e-selector={e2e}
      onClick={onClick}
      data-gtm={gtm}
      href={href}
      target={hrefTarget}
      rel={hrefTarget ? 'noopener' : ''}
    >
      <div
        className={clsx(classes.buttonContent, {
          [classes.buttonContentReverse]: icon?.placeAfterLabel,
          [classes.buttonContentDownload]: buttonType === 'download',
        })}
      >
        {icon && icon.fontAwesomeIconSrc && (
          <FaIconLight icon={icon.fontAwesomeIconSrc} />
        )}
        {icon && icon.iconName && (
          <img
            className={classes.svgIcon}
            src={nameIconMap[icon.iconName]}
            height={ICON_HEIGHT_BY_BUTTON_SIZE_MAP[buttonSize]}
            alt=""
          />
        )}
        <span>{label}</span>
      </div>
    </a>
  );

  return href && !checkIfHrefIsExternal(href) ? (
    <I18nLink href={href} hash={hash}>
      {styledButton}
    </I18nLink>
  ) : (
    styledButton
  );
};

export default Button;
