import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';

import { NavigationAnchorLabel } from '../NavigationGroup';

import classes from './index.module.scss';

import { I18nLink } from '@/components/I18nLink';

interface Props {
  name: string;
  href: string;
  hash?: string;
  isExternalUrl?: boolean;
}

export const DirectLink: FC<Props> = ({ hash, href, name, isExternalUrl }) => {
  const { t } = useTranslation('navbar');

  if (!href) return null;

  return (
    <li
      className={classes.navigationItem}
      data-e2e-selector="navigation-bar-item"
    >
      {isExternalUrl ? (
        <NavigationAnchorLabel as="a" href={href}>
          {t(name)}
        </NavigationAnchorLabel>
      ) : (
        <I18nLink href={href} hash={hash}>
          <NavigationAnchorLabel as="a">{t(name)}</NavigationAnchorLabel>
        </I18nLink>
      )}
    </li>
  );
};
