import React from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';
import type { FC, SVGProps } from 'react';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export type FaIconProps = SVGProps<SVGSVGElement> & {
  icon: IconDefinition;
  className?: string;
  size?: FontAwesomeIconProps['size'];
  spin?: boolean;
};

const xmlns = 'http://www.w3.org/2000/svg';

export const FaIconLight: FC<FaIconProps> = (props) => {
  const { icon: iconProps, children, spin, size, ...rest } = props;

  const { prefix, iconName, icon } = iconProps;
  const [width, height, , , svgPathData] = icon;

  const dataFa = `${prefix}-${iconName}`;

  const fill = props.color ? props.color : 'currentColor';

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      xmlns={xmlns}
      role={'img'}
      aria-hidden="true"
      data-fa={dataFa}
      {...rest}
      className={clsx(
        `svg-inline--fa fa-${iconName}`,
        {
          [`fa-${props.size}`]: size,
          [`fa-spin`]: spin,
        },
        props.className,
      )}
    >
      {children}
      {Array.isArray(svgPathData) ? (
        <g>
          <path fill={fill} d={svgPathData[0]} />
          <path fill={fill} d={svgPathData[1]} />
        </g>
      ) : (
        <path fill={fill} d={svgPathData} />
      )}
    </svg>
  );
};
