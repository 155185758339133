import { ParsedUrlQuery } from 'querystring';

import { useContext } from 'react';

import ConfigsContext from '../../contexts/configs';
import { I18nContext } from '../../contexts/i18n';
import { getInternationalizedHref } from '../../helpers/getInternationalizedHref';

type GetInternationalizedHref = (
  href: string,
  locale?: string,
  query?: ParsedUrlQuery,
) => string | undefined;

export const useInternationalizedHref = (): GetInternationalizedHref => {
  const { currentLocale, domains, locales } = useContext(I18nContext);
  const { routes } = useContext(ConfigsContext);

  return (href: string, locale: string = currentLocale, query) => {
    return getInternationalizedHref({
      domains,
      href,
      locale,
      locales,
      routes,
      query,
    });
  };
};
