import { ParsedUrlQuery } from 'querystring';

import isEmpty from 'lodash/isEmpty';

import { Route } from '../../types/Config/Routes';
import { Domain } from '../../types/Domain';
import { Locale } from '../../types/Locale';

const CUSTOM_PAGE_SUFFIX_LENGTH = -3;
const TRAILING_SLASH = /\/$/;
const EMPTY_STRING = '';
const REGISTRATION_PATH_WITH_CODE = '/registration/:code';
const REGISTRATION_PATH_WITHOUT_CODE = '/registration';

type GetInternationalizedHref = ({
  href,
  locale,
}: {
  href: string;
  locale: string;
  domains: Domain[];
  locales: Locale[];
  routes: Route[];
  query?: ParsedUrlQuery;
}) => string | undefined;

const fillUrlQueryKeys = (href: string, query: ParsedUrlQuery): string => {
  let newHref = href;

  Object.entries(query).forEach(([key, value]) => {
    if (typeof value === 'string') {
      newHref = newHref.replace(`:${key}`, value);
    } else if (Array.isArray(value)) {
      newHref = newHref.replace(`:${key}`, value[0]);
    }
  });

  return newHref;
};

export const getInternationalizedHref: GetInternationalizedHref = ({
  href,
  locale,
  domains,
  locales,
  routes,
  query = {},
}) => {
  const targetLocale = locale;
  const isStaticHref = href?.startsWith('/static');
  let targetHref = href;

  if (isEmpty(query) && targetHref.includes(REGISTRATION_PATH_WITH_CODE)) {
    targetHref = targetHref.replace(
      REGISTRATION_PATH_WITH_CODE,
      REGISTRATION_PATH_WITHOUT_CODE,
    );
  }

  const shouldCustomPageSuffixBeTrimmed = (): boolean => {
    if (routes.some((route) => route.page === targetHref)) {
      return false;
    }

    return locales.some((locale) => {
      return targetHref?.endsWith(`/${locale}`);
    });
  };

  if (shouldCustomPageSuffixBeTrimmed()) {
    targetHref = targetHref.slice(0, CUSTOM_PAGE_SUFFIX_LENGTH);
  }

  if (!locales.includes(targetLocale)) {
    return;
  }

  const localeDomain = domains.find((domain) => {
    return domain.locales.includes(targetLocale);
  });

  const route = routes.find((route) => {
    return (
      route.page === targetHref ||
      Object.values(route.locales).some(
        (localRoute) => localRoute.pathName === targetHref,
      )
    );
  });

  const localeRoute = route?.locales[targetLocale];

  if (!localeRoute && !isStaticHref) {
    return;
  }

  const targetProtocol = localeDomain?.http ? 'http://' : 'https://';
  let localizedHref = localeRoute?.pathName;

  if (localeRoute?.pathName.includes('/:') && isEmpty(query)) {
    localizedHref = '';
  }

  const finalHref =
    (!localeDomain?.forcePrefix &&
      localeDomain?.defaultLocale === targetLocale) ||
    isStaticHref
      ? `${targetProtocol}${localeDomain?.domain}${localizedHref ?? targetHref}`
      : `${targetProtocol}${localeDomain?.domain}/${targetLocale}${
          localizedHref ?? targetHref
        }`;

  const finalHrefWithoutTrailingSlash = finalHref.replace(
    TRAILING_SLASH,
    EMPTY_STRING,
  );

  return fillUrlQueryKeys(finalHrefWithoutTrailingSlash, query);
};
