import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useEvent, useWindowScroll } from 'react-use';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { AccountButtons } from './components/AccountButtons';
import { Logo, NAVBAR_LOGO_WIDTH } from './components/Logo';
import { NavigationBar } from './components/NavigationBar';
import { HamburgerNavigation } from './components/HamburgerNavigation';
import classes from './index.module.scss';

const SimpleLocaleChanger = dynamic(() =>
  import('./components/SimpleLocaleChanger').then(
    (mod) => mod.SimpleLocaleChanger,
  ),
);

interface Props {
  transparentOnTop?: boolean;
  simpleUI?: boolean;
  withoutFixedNavbar?: boolean;
  hideSimpleUiLocaleChanger?: boolean;
}

type NavbarBackgroundProps = {
  children: ReactNode;
} & Pick<Props, 'transparentOnTop' | 'withoutFixedNavbar' | 'simpleUI'>;

const NavbarBackground: FC<NavbarBackgroundProps> = ({
  children,
  transparentOnTop,
  withoutFixedNavbar,
  simpleUI,
}) => {
  const { y } = useWindowScroll();
  const [isScrolledTop, setIsScrolledTop] = useState(false);
  const [isHamburgerNavOpen, setIsHamburgerNavOpen] = useState(false);

  useEffect(() => {
    setIsScrolledTop(y <= 0);
  }, [y]);

  useEvent('load', () => {
    setIsScrolledTop(y <= 0);
  });

  const { events } = useRouter();

  useEffect(() => {
    const closeOnRouteChange = () => {
      setIsHamburgerNavOpen(false);
    };

    events.on('routeChangeStart', closeOnRouteChange);

    return () => {
      events.off('routeChangeStart', closeOnRouteChange);
    };
  }, [events]);

  return (
    <div
      className={clsx(classes.navbarContainer, {
        [classes.navbarContainerTransparent]:
          isScrolledTop && transparentOnTop && !isHamburgerNavOpen,
        [classes.navbarStatic]: withoutFixedNavbar,
      })}
    >
      <nav
        style={
          { '--navbarLogoWidth': NAVBAR_LOGO_WIDTH + 'px' } as CSSProperties
        }
        className={simpleUI ? classes.simpleNavbar : classes.navbar}
      >
        {children}
        {!simpleUI && (
          <HamburgerNavigation
            isOpen={isHamburgerNavOpen}
            onToggle={setIsHamburgerNavOpen}
          />
        )}
      </nav>
    </div>
  );
};

const Navbar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  transparentOnTop = false,
  simpleUI = false,
  withoutFixedNavbar = false,
  hideSimpleUiLocaleChanger = false,
}) => {
  return (
    <NavbarBackground
      withoutFixedNavbar={withoutFixedNavbar}
      transparentOnTop={transparentOnTop}
      simpleUI={simpleUI}
    >
      {simpleUI ? (
        <>
          <Logo />
          {!hideSimpleUiLocaleChanger && <SimpleLocaleChanger />}
        </>
      ) : (
        <>
          <Logo />
          <NavigationBar />
          <AccountButtons className={classes.accountButtons} />
        </>
      )}
    </NavbarBackground>
  );
};

export default Navbar;
