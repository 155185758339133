import React from 'react';
import Image from 'next/image';

import logoImageLight from '../../../../public/images/logo.svg';
import logoImageDark from '../../../../public/images/logo-dark.svg';
import { I18nLink } from '../../../I18nLink';

import classes from './index.module.scss';

interface Props {
  dark?: boolean;
}

export const NAVBAR_LOGO_HEIGHT = '40';
export const NAVBAR_LOGO_WIDTH = '165';

export const Logo: React.FunctionComponent<Props> = ({ dark = false }) => {
  return (
    <div className={classes.logo}>
      <I18nLink href={'/'}>
        <a className={classes.logoAnchor} suppressHydrationWarning>
          <Image
            priority
            src={dark ? logoImageDark.src : logoImageLight.src}
            alt="SMSAPI logo"
            width={NAVBAR_LOGO_WIDTH}
            height={NAVBAR_LOGO_HEIGHT}
          />
        </a>
      </I18nLink>
    </div>
  );
};
