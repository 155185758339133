import React, { useContext } from 'react';

import { NavigationItem } from './components/NavigationItem';
import classes from './index.module.scss';

import ConfigsContext from '@/contexts/configs';

export const NavigationBar: React.FunctionComponent = () => {
  const { transformedNavbar: navItems } = useContext(ConfigsContext);

  return (
    <div className={classes.navigationBar}>
      <ul className={classes.navigationItemList}>
        {navItems.map(
          ({
            externalUrl,
            navItem,
            navItemHash,
            navItemHref,
            navItemSubitems,
          }) => (
            <NavigationItem
              label={{
                name: navItem.label,
                href: navItemHref,
                hash: navItemHash,
                isExternalUrl: !!externalUrl,
              }}
              subitems={navItemSubitems}
              key={navItem.label}
            />
          ),
        )}
      </ul>
    </div>
  );
};
