import { ParsedUrlQuery } from 'querystring';

import React, { useContext } from 'react';
import Link from 'next/link';

import { Locale } from '../../types/Locale';
import { HOME_ROUTE } from '../../constants';
import { useInternationalizedHref } from '../../hooks/useInternationalizedHref';
import { I18nContext } from '../../contexts/i18n';

export interface I18nLinkProps {
  fallbackHref?: boolean;
  hash?: string;
  href: string;
  locale?: Locale;
  urlParam?: ParsedUrlQuery;
  hardRefresh?: boolean;
  className?: string;
}

export const I18nLink: React.FunctionComponent<
  React.PropsWithChildren<I18nLinkProps>
> = (props) => {
  const {
    className,
    hardRefresh,
    children,
    fallbackHref,
    locale,
    hash,
    href,
    urlParam,
  } = props;

  const { currentLocale } = useContext(I18nContext);

  const internationalizedHref = useInternationalizedHref();

  const linkHref =
    internationalizedHref(href, locale ?? currentLocale, urlParam) ||
    internationalizedHref(HOME_ROUTE, locale ?? currentLocale, urlParam);

  if (!linkHref && !fallbackHref) return null;

  const finalHref = `${linkHref}${hash ? `#${hash}` : ''}`;

  return hardRefresh ? (
    <a className={className} href={finalHref}>
      {children}
    </a>
  ) : (
    <Link
      href={finalHref}
      locale={false}
      prefetch={false}
      passHref
      legacyBehavior
    >
      {children}
    </Link>
  );
};
